module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-163219293-1","AW-656248674"],"pluginConfig":{"head":true,"anonymize":true,"respectDNT":true,"pageTransitionDelay":0}},
    },{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"Udemy-course","pages":[{"type":"Page","match":"/:uid","path":"/","component":"/opt/build/repo/src/templates/page.js"},{"type":"Footerpages","match":"/:uid","path":"/","component":"/opt/build/repo/src/templates/footerPage.js"},{"type":"Blogpage","match":"/blog/:uid","path":"/","component":"/opt/build/repo/src/templates/blogPage.js"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://eltop10.co"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/icon.svg"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics-gdpr/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-163219293-1","enableDevelopment":true,"anonymizeIP":true,"autoStartWithCookiesEnabled":false,"reactGaOptions":{"debug":true,"gaOptions":{"sampleRate":100}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
